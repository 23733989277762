import React from 'react'
import "./Portfolio.css"
import IMG1 from "../../assets/portfolio1.webp"
import IMG2 from "../../assets/portfolio2.jpg"
import IMG3 from "../../assets/pass.jfif"
import IMG4 from "../../assets/FbAd1.jpg"
import IMG5 from "../../assets/Telefon_Ny.png"
import IMG6 from "../../assets/portfolio6.jpg"
import IMG7 from "../../assets/portfolio7.png"
import IMG8 from "../../assets/portfolio8.jpg"
import IMG10 from "../../assets/portfolio10.png"
import IMG11 from "../../assets/portfolio11.jpg"
import IMG12 from "../../assets/portfolio12.jpg"
import IMG13 from "../../assets/portfolio13.jpg"
import IMG14 from "../../assets/portfolio14.jpg"
import IMG15 from "../../assets/portfolio15.png"
import IMG16 from "../../assets/portfolio16.jpg"
import IMG17 from "../../assets/portfolio17.png"
import IMG18 from "../../assets/portfolio18.png"
import IMG19 from "../../assets/portfolio19.jpg"

const data = [
  {
    id: 18,
    image: IMG18,
    title: "Customer support agent Lysa",
    website: "https://react-with-langchain-lysa-1337.streamlit.app/",
    desription: "I developed a PoC for a basic Lysa customer support agent with some tools based on the ReAct agent framework"
  },
  {
    id: 19,
    image: IMG19,
    title: "LangGraph by LangChain Academy",
    website: "https://academy.langchain.com/courses/intro-to-langgraph",
    desription: "Introduction to LangGraph, covering how to build agents, different memory solutions, and multi-agent systems, etc"
  },
  {
    id: 16,
    image: IMG16,
    title: "Ai agent for sorting emails",
    website: "",
    desription: "Built an AI agent that sorts emails into different folders, the code is private for now"
  },
  
  {
    id: 15,
    image: IMG15,
    title: "poisonedRAG",
    website: "https://github.com/MorrisSimons/poisonedRAG",
    desription: "I developed a RAG model and applied the attack strategies from the 'Poisoned RAG' paper, causing the model to make incorrect decisions"
  },
  {
    id: 14,
    image: IMG14,
    title: "Self flying drone for FMV",
    website: "https://www.bth.se/nyheter/studenternas-dronarsystem-fangar-fmvs-intresse-kan-bli-en-del-av-framtidens-forsvar/",
    desription: "Part of the 9 people team that built a self flying kamikaze drone for FMV in a couple of months during the summer of 2024"
  },
  {
    id: 13,
    image: IMG13,
    title: "Flashcard testing app",
    website: "https://github.com/MorrisSimons/test-program",
    desription: "A simple flashcard testing app that I built in streamlit"
  },
  {
      id: 12,
      image: IMG17,
      title: "Simple RAG Model via Streamlit",
      website: "",
      desription: "I developed a simple RAG model to retrieve tanker freight prices, integrated with an Streamlit chat interface code is private for now"
  },
  {
    id: 11,
    image: IMG11,
    title: "Machine Learning Projects from a ML course",
    website: "https://github.com/MorrisSimons/SCH_DV2599-Maskinlarning",
    desription: "This git repo contains a collection of simple machine learning projects that I have worked on in the course DV2599."
  },
  {
    id: 10,
    image: IMG10,
    title: "Realestate portfolio Mapping",
    website: "https://www.google.com/maps/d/u/0/edit?mid=1AZNoQBmVK8USozeKca8MvwOgsW1J-d8&usp=sharing",
    desription: "uilt a program that maps and mapped out over 2,500 estates from different portfolios to visualize them in a new way. The code is private for now"
  },
  {
    id: 8,
    image: IMG7,
    title: "Award winning Figma Design of Canvas",
    website: "https://www.figma.com/proto/dzZzAScZ7MNNQZADaTGgwD/Usability-and-Interaction-Design---Group-4?node-id=96-3721&scaling=scale-down&page-id=55%3A809&starting-point-node-id=96%3A3721&show-proto-sidebar=1",
    desription: "Developed with a couple of students this figma design that is now used to teach new students about design at BTH",
  },
  {
    id: 7,
    image: IMG1,
    title: "Ai captcha solver useing CNN",
    website: "https://github.com/MorrisSimons/Free_ai_captcha_solver_model",
    desription: "Captcha solver for disorted text images, Code avalible on request",
  },
  {
    id: 6,
    image: IMG8,
    title: "25 hours pytorch course",
    website: "",
    desription: "25 hour pytorch course that I have been watching"
  },
  {
    id: 5,
    image: IMG4,
    title: "Citas Rapidas",
    website: "https://citas-rapidas.com/",
    desription: "Booking agency for timeslots on SAT in Mexico, 300+ Customers."
  },

  {
    id: 4,
    image: IMG3,
    title: "Passtider-Snabbt.se",
    website: "https://www.trustpilot.com/review/passtider-snabbt.se?languages=all&sort=recency",
    desription: "Swedish Booking agency for passports and id",
  },
  {
    id: 3,
    image: IMG2,
    title: "Blocket license plate Recognition",
    website: "https://clipchamp.com/watch/AmfrTpRCk8k",
    desription: "License plate recognition used for valuation of cars on blocket.se, Ai modelbuilt with Tensorflow",
  },
  {
    id: 2,
    image: IMG5,
    title: "Bokaförarprov.se",
    webiste: "https://bokaforarprov.se/",
    desription: "Booking agency for your driving test in sweden"
  },
  {
    id: 1,
    image: IMG6,
    title: "SL Planknings App",
    website: "",
    desription: "Made an app for free travel on sweedish public transport, no demo avalible",
  }
]



const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent work</h5>
      <h2>Awards & Portfolio & Extras</h2>
      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, website, desription }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <p>{desription}.</p>
                <div className="portfolio__item-cta">
                  <a href={website} className="btn btn-primary">Read More</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio
